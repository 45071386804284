// To see this message, add the following to the `<head>` section in your
// views/layouts/application.html.erb
//
//    <%= vite_client_tag %>
//    <%= vite_javascript_tag 'application' %>
// console.log('Vite ⚡️ Rails')

// If using a TypeScript entrypoint file:
//     <%= vite_typescript_tag 'application' %>
//
// If you want to use .jsx or .tsx, add the extension:
//     <%= vite_javascript_tag 'application.jsx' %>

//console.log('Visit the guide for more information: ', 'https://vite-ruby.netlify.app/guide/rails')

// Example: Load Rails libraries in Vite.
//
// import * as Turbo from '@hotwired/turbo'
// Turbo.start()
//
// import ActiveStorage from '@rails/activestorage'
// ActiveStorage.start()
//
// // Import all channels.
// const channels = import.meta.globEager('./**/*_channel.js')

// Example: Import a stylesheet in app/frontend/index.css
// import '~/index.css'

// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

//import "@rails/ujs"
import Rails from "@rails/ujs"
import "@hotwired/turbo-rails"
import "../channels"

import jCrop from "jquery-jcrop"
import 'bootstrap'

//import Bloodhound from 'corejs-typeahead/dist/bloodhound.js';
import "corejs-typeahead/dist/typeahead.jquery.js";

import Tags from "bootstrap5-tags";

Rails.start();
//Turbolinks.start();

function imgSelect(selection) {
  var canvas = document.createElement('canvas');
  var img = jQuery('#image_input img')[0];
  var elem = jQuery('#image_input');
  var cropW = elem.attr('data-crop-w');
  var cropH = elem.attr('data-crop-h');
  var cropName = elem.attr('data-crop-name');

  canvas.width = cropW * 2;
  canvas.height = cropH * 2;

  var ctx = canvas.getContext('2d');
  ctx.drawImage(img, selection.x, selection.y, selection.w, selection.h, 0, 0, canvas.width, canvas.height);

  jQuery('#' + cropName + '_crop_x').val(selection.x)
  jQuery('#' + cropName + '_crop_y').val(selection.y)
  jQuery('#' + cropName + '_crop_w').val(selection.w)
  jQuery('#' + cropName + '_crop_h').val(selection.h)

  jQuery('#image_output').attr('src', canvas.toDataURL());
}

function initImgCrop() {
  var elem = jQuery('#image_input');
  var cropW = elem.attr('data-crop-w');
  var cropH = elem.attr('data-crop-h');
  var cropName = elem.attr('data-crop-name');

  elem.css("border: 1px solid red");

  jQuery('img', elem).Jcrop({
    boxWidth: cropW * 2,
    boxHeight: cropH * 2,
    bgColor: 'black',
    bgOpacity: .6,
    setSelect: [
      jQuery('#' + cropName + '_crop_x').val(),
      jQuery('#' + cropName + '_crop_y').val(),
      parseFloat(jQuery('#' + cropName + '_crop_x').val()) + parseFloat(jQuery('#' + cropName + '_crop_w').val()),
      parseFloat(jQuery('#' + cropName + '_crop_y').val()) + parseFloat(jQuery('#' + cropName + '_crop_h').val())
    ],
    aspectRatio: cropW/cropH,
    onChange: imgSelect
  });
}

jQuery(document).on('turbo:load', function() {

  var coverLoader = document.getElementById('story_cover');
  if(coverLoader) {
    coverLoader.addEventListener('change', handleImage, false);
    initImgCrop();

    var storyTitle = document.getElementById('story_title');
    storyTitle.addEventListener('keyup', function(e) {
      jQuery('.cover .title').text(jQuery(event.target).val());
    }, false);

    var storySubtitle = document.getElementById('story_subtitle');
    storySubtitle.addEventListener('keyup', function(e) {
      jQuery('.cover .subtitle').text(jQuery(event.target).val());
    }, false);

    var storyCoverStyle = document.getElementById('story_cover_style');
    storyCoverStyle.addEventListener('change', function(e) {
      jQuery('.cover').removeClass(jQuery.map(jQuery('option', event.target), function(e) { return e.value; }));
      jQuery('.cover').addClass(jQuery(event.target).val());
    }, false);
  }

  var imageLoader = document.getElementById('person_image');
  if(imageLoader) {
    imageLoader.addEventListener('change', handleImage, false);
    initImgCrop();

    var personGender = document.getElementById('person_gender');
    personGender.addEventListener('change', function(e) {
      if(! jQuery('#image_input img')[0])
        jQuery('#image_output').attr('src', '/person_' + jQuery(event.target).val() + '.png')
    }, false);
  }

  var userImageLoader = document.getElementById('user_image');
  if(userImageLoader) {
    userImageLoader.addEventListener('change', handleImage, false);
    initImgCrop();
  }

  jQuery('.person-card').hide();

  jQuery("a.person").on('mouseenter', function(e) {
    var target = jQuery(e.target);
    var target_pos = target.position();
    var person_id = e.target.href.split('/')[4];
    var person_element = jQuery("#person-" + person_id);

    // try position over link

    person_element.show().css('top', target_pos.top - person_element.outerHeight()).css('left', target_pos.left);


    if(false)  {
      // position under link
      person_element.css('top', target_pos.top + target.height()).css('left', target_pos.left);
    }
  });

  jQuery("a.person").on('mouseleave', function(e) {
    var person_id = e.target.href.split('/')[4];
    var person_div_id = "#person-" + person_id;

    jQuery(person_div_id).hide();
  });


  if(document.getElementById('story-add-person')) {
    var people = JSON.parse(jQuery('#story-add-person .typeahead').attr('data-tt-values'));

    var people = new Bloodhound({
      datumTokenizer: Bloodhound.tokenizers.obj.whitespace('name'),
      queryTokenizer: Bloodhound.tokenizers.whitespace,
      local: people
    });

    jQuery('#story-add-person .typeahead').typeahead({
      hint: true,
      highlight: true,
      minLength: 1
    }, {
      name: 'people',
      display: 'name',
      source: people,
      templates: {
        suggestion: function(data) {
          return '<p><img src="/people/' + data.id + '/image" width="42" height="42"> ' + data.name + '</p>';
        }
      }
    }).on('typeahead:selected', function (e, suggestion, name) {
      jQuery("#person_id").val(suggestion.id)
      jQuery(e.target).closest('form').submit();
    });
  }

  if(document.getElementById('story_tag_names')) {
    Tags.init('select#story_tag_names', {
      allowNew: true,
      allowClear: true,
      badgeStyle: 'secondary',
      separator: ','
    });
  }
});

function handleImage(e){

  var reader = new FileReader();

  reader.onload = function(event){
    var elem = jQuery('#image_input');
    var cropW = elem.attr('data-crop-w');
    var cropH = elem.attr('data-crop-h');

    var image = new Image();
    var imageW = cropW*2;

    image.onload = function(){
      var canvas = document.createElement('canvas');

      canvas.width = image.width;
      canvas.height = image.height;

      var ctx = canvas.getContext('2d');
      ctx.drawImage( image, 0, 0, canvas.width, canvas.height );

      elem.html(['<img src="', canvas.toDataURL(), '"/>'].join(''));

      jQuery('img', elem).Jcrop({
        boxHeight: 400,
        bgColor: 'black',
        bgOpacity: .6,
        setSelect: [0, 0, cropW*2, cropH*2],
        aspectRatio: cropW/cropH,
        onChange: imgSelect
      });
    }
    image.src = event.target.result;
	}
	reader.readAsDataURL(e.target.files[0]);
}